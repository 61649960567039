<template>
    <div class="mb-24">
        <card title="References">

            <div class="w-full">
                <div class="-mx-3">
                    <div class="flex flex-wrap w-full">
                        <div class="w-1/3 mb-4 px-3">
                            <t-input v-model="data.references.consignor_reference" label="Consignor reference"></t-input>
                        </div>

                        <div class="w-1/3 mb-4 px-3">
                            <t-input v-model="data.references.consignee_reference" label="Consignee reference"></t-input>
                        </div>

                        <div class="w-1/3 mb-4 px-3">
                            <t-input v-model="data.references.purchase_order_number" label="Purchase order number"></t-input>
                        </div>

                        <div class="w-1/3 mb-4 px-3">
                            <t-input v-model="data.references.sales_order_number" label="Sales order number"></t-input>
                        </div>

                        <div class="w-1/3 mb-4 px-3">
                            <t-input v-model="data.references.project_number" label="Project number"></t-input>
                        </div>
                    </div>
                </div>
            </div>
        </card>

        <card title="Terms of delivery" class="mt-8" body-class="overflow-y-visible">
            <div class="w-full">
                <div class="-mx-3">
                    <div class="flex flex-wrap w-full">
                        <div class="w-1/3 mb-4 px-3">
                            <multi-select v-model="data.terms.type" :options="['EXW', 'FCA', 'DAP']" label="Type"></multi-select>
                        </div>

                        <div class="w-1/3 mb-4 px-3">
<!--                            <multi-select v-model="data.terms.location" @search="fetchLocations($event)" :options="locationSuggestions" label="Location"></multi-select>-->
                            <t-input v-model="data.terms.location" :error="hasError('terms.location')" label="Location"></t-input>
                        </div>
                    </div>
                </div>
            </div>
        </card>

        <card title="Goods information" class="mt-8" body-class="overflow-y-visible">
            <div class="w-full">
                <div class="-mx-3">
                    <div class="flex flex-wrap w-full">
                        <div class="w-3/5 mb-4 px-3">
                            <t-input v-model="data.goods_information.content" :error="hasError('goods_information.content')" label="Content*"></t-input>
                        </div>

                        <div class="w-1/5 mb-4 px-3">
                            <t-input v-model="data.goods_information.value" label="Value"></t-input>
                        </div>

                        <div class="w-1/5 mb-4 px-3">
                            <multi-select v-model="data.goods_information.currency" :error="hasError('goods_information.currency')" :allow-clear="false" :options="currencies" label="Currency"></multi-select>
                        </div>
                    </div>
                </div>
                <checkbox name="invoice_details" v-model="data.goods_information.invoice_details">
                    Enter invoice details
                </checkbox>
                <p v-if="data.goods_information.invoice_details" class="text-gray-600 text-sm italic mt-2">
                    Great, you will be able to fill them in the next step
                </p>
            </div>
        </card>

        <card title="Payment details" class="mt-8" body-class="overflow-y-visible">
            <div class="w-full">
                <div class="-mx-3">
                    <div class="flex flex-wrap w-full" v-if="accountNumberOptions.length > 0">
                        <div class="w-1/3 mb-4 px-3">
                            <multi-select @change="accountNumberChange($event)" :options="accountNumberOptions" select-label="label" select-value="value" :error="hasError('payment_details.account_number')" label="Account number"></multi-select>
                        </div>
                    </div>

                    <div class="flex flex-wrap w-full" v-if="accountNumberOptions.length === 0">
                        <div class="mb-4 px-3">
                            <modal-field label="Account type">
                                <div class="flex mt-2">
                                    <div class="flex items-center">
                                        <input type="radio" v-model="data.payment_details.account_type" value="import" class="mr-2">
                                        Import
                                    </div>

                                    <div class="flex items-center ml-4">
                                        <input type="radio" v-model="data.payment_details.account_type" value="export" class="mr-2">
                                        Export
                                    </div>
                                </div>
                            </modal-field>
                        </div>
                        <div class="w-1/5 mb-4 ml-8">
                            <t-input v-model="data.payment_details.account_number" label="Account number" :error="hasError('payment_details.account_number')"></t-input>
                        </div>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>
<script>

import TInput from "~/components/TInput";
import MultiSelect from "~/components/MultiSelect";
import Checkbox from "~/components/Checkbox";
import ModalField from "~/components/ModalField";

export default {
    name: "ShipmentDetails",

    components: {ModalField, Checkbox, MultiSelect, TInput},

    data: () => ({
        locationSuggestions: [],
    }),

    computed: {
        data() {
            return this.$store.getters[`price-leadtime/shipmentDetails`];
        },
        result() {
            return this.$store.getters[`price-leadtime/result`].item;
        },
        currencies() {
            return this.$store.getters[`price-leadtime/currencies`];
        },
        currentStep() {
            return this.$store.getters["price-leadtime/currentStep"];
        },
        errors() {
            return this.$store.getters['price-leadtime/activeSteps'][this.currentStep].errors
        },
        accountNumberOptions() {
            const accounts = [];

            this.result.accounts.forEach((account) => {

                const same = account.export_account_number === account.import_account_number;

                accounts.push({
                    'label': same ? account.import_account_number : `${account.import_account_number} (import)`,
                    'value': account.import_account_number
                })

                if (!same) {
                    accounts.push({
                        'label': `${account.export_account_number} (export)`,
                        'value': account.export_account_number
                    })
                }
            })

            return accounts
        },
    },

    methods: {
        removeDocument(index) {
            this.data.documents.splice(index, 1)
        },

        accountNumberChange(data) {
            this.data.payment_details.account_number = data.value
        },

        hasError(key) {
            return this.errors[`shipment_details.${key}`];
        },

        fetchLocations(search) {
            if (! search) {
                return false
            }

            const vm = this
            const service = new google.maps.places.AutocompleteService()

            service.getPlacePredictions({
                input: search,
                componentRestrictions: { country: 'SE' }
            }, (predictions) => {
                vm.locationSuggestions = predictions.map((location) => {
                    return location.description
                })
            })
        },
    }

}
</script>
